import { Link } from "react-router-dom";
import React from "react";
import { MAIN_URL } from "../API";

export default function Changelog() {
    return (
        <React.Fragment>
            <div className="mt-2" >
                <h3>0.6.0</h3>
                <p>
                    Mortar has found a new home!<br />
                    Hello everyone,<br />
                    Due to some issues with the previous website, we have decided to move to our new home at {MAIN_URL}. <br />
                    We are currently working on some exciting new features, please stay tuned and join our discord!
                </p>
            </div>

            <div className="mt-2">
                <h4>0.5.2</h4>
                <p>
                    Minor bugfix:<br />
                    fixed bugs
                </p>
            </div>
            <div className="mt-2" >
                <h4>0.5.1</h4>
                <div>
                    2023/04/27T04:00:00+0000
                </div>
                <p>
                    Minor Bugfix: <br />
                    TL;DR: Performance Improvements & Refixing fixed bugs
                </p>
                <ul>
                    <li><Link to="https://discord.com/channels/1092544577732350062/1098025520689594440" >ReFixed bug where some equations would not render</Link></li>
                    <li>Fixed slow loading issues (Initial page load should be much faster)</li>
                </ul>
            </div>

            <div className="mt-2" >
                <h3>0.5.0</h3>
                <div>
                    2023/04/24T08:00:00+0000
                </div>
                <p>
                    MAJOR update:<br />
                    TL;DR: Share Question & Mark Question as done
                </p>
                <ul className="text-start" >
                    <li>Added Share/Copy link feature to share questions</li>
                    <li>Added Mark as Done feature to track progress (Kudos to &lt;REDACTED&gt; for helping design the feature)</li>
                    <li><Link to="https://discord.com/channels/1092544577732350062/1098025520689594440" >Fixed bug where some equations would not render</Link></li>
                    <li><Link to="https://discord.com/channels/1092544577732350062/1097001751908974592" >Fixed bug where videos would continue playing after close</Link></li>
                    <li>Updated difficulty function</li>
                </ul>
            </div>

            <div className="mt-2" >
                <h4>
                    0.4.2
                </h4>
                <div>
                    2023/04/15T00:00:00+0000
                </div>
                <p>
                    TL;DR: Major bugfix & UI update, fixed many issues with the LaTeX display code.
                </p>
                <ul className="text-start">
                    <li><Link to="https://discord.com/channels/1092544577732350062/1096609931504926730" >Fixed a bug where "all questions" was broken</Link></li>
                    <li><Link to="https://discord.com/channels/1092544577732350062/1096873037128794212" >Added mhchem, removing the \ce display issue</Link></li>
                    <li>Fixed issues with tables display</li>
                    <li>Fixed issues with no. of marks alignment</li>
                </ul>
            </div>
            <div className="mt-2" >
                <h6>0.4.1</h6>
                <p>
                    Added Calculator/Non-Calculator Coloring. The "all questions" sections also work now.
                </p>
            </div>
            <div className="mt-2" >
                <h3>
                    0.4.0
                </h3>
                <div>
                    2023/04/10T00:00:00+0000
                </div>
                <code>
                    Write me a update message for an update to M252A1 is a public archiver for a certain site that starts with Revision, ends with Village, and is two words. The update adds prediction exams to the site.
                </code>
                <div className="mt-2" >
                    <p>Hello!</p>
                    <p>We are excited to announce an update to M252A1, the public archiver for Revision Village! The update brings a brand new feature to the site - prediction exams.</p>
                    <p>Now, you can take prediction exams to better prepare for your upcoming exams. These exams are specifically designed to help you identify areas where you may need more practice, and to give you a better idea of what to expect on test day.</p>
                    <p>We hope that this new feature will be a valuable addition to your Revision Village experience. As always, if you have any questions or feedback, please don't hesitate to reach out to us.</p>
                    <p>Thank you for using M252A1, and we wish you the best of luck on your exams!</p>
                </div>
            </div>
            <div className="mt-2" >
                <h3>
                    0.3.0
                </h3>
                <div>
                    2023/04/08T00:00:00+0000
                </div>
                <code>
                    git commit -m "proxies"
                </code>
                <div>
                    Feature update:
                </div>
                <ul className="text-start">
                    <li>Added "Ghost Mode"</li>
                    <li>Added Video Embeds</li>
                    <li>Added extra "features" for downloaded versions</li>
                </ul>
            </div>
            <div className="mt-2" >
                <h3>
                    0.2.1
                </h3>
                <div>
                    2023/04/06T05:20:00+0000
                </div>
                <code>
                    git commit -m "bugfixes on image loading & UI"
                </code>
                <div>
                    Minor Bugfixes in Image Loading & UI
                </div>
            </div>
            <div className="mt-2" >
                <h3>0.2.0</h3>
                <div>
                    2023/04/01T23:00:00+0000
                </div>
                <code>
                    git commit -m "videos & difficulty"
                </code>
                <ul className="text-start">
                    <li>Added difficulty levels & Video playback</li>
                </ul>
            </div>
            <div className="mt-2" >
                <h3>Maybe you can download this now???</h3>
                <code>
                    git commit -m "made changes"
                </code>
                <ul className="text-start">
                    <li>Improved downloadability</li>
                    <li>Improved mobile responsiveness</li>
                    <li>Extra UI improvements & QoL currently in the pipeline</li>
                </ul>
            </div>
        </React.Fragment>
    )
}