import { useEffect, useState, useRef } from "react";
import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import remarkDirective from "remark-directive";
import remarkRehype from "remark-rehype";
import rehypeKatex from "./plugins/rehypeKatex";
import { rehypeUrls } from "./plugins/rehypeURLs";
import rehypeStringify from "rehype-stringify";
import CryptoJS from "crypto-js";

import "./css/QBViewer.scss";

import QuestionStorage from "./plugins/saveQuestion";

import "katex/dist/contrib/mhchem.js";
import "katex/dist/katex.min.css";
import { remarkMarksDirective } from "./plugins/remarkMarksDirective";
import { Check, ChevronsLeft, ChevronsRight, FileText, Share, X, Youtube } from "react-feather";
import { KEY } from "./plugins/key";
import { computeURL, loadVideo } from "./API";

export type QuestionType = {
    id: string,
    reference: string,
    images: {
        question_id: string,
        file_id: string,
        filename: string,
        content_type: string,
        hash: string,
        url: string
    }[],
    content: string,
    solutions: {
        name: string,
        url: string
    }[],
    markScheme: string,
    paper: {
        id: string,
        calculatorAllowed: boolean,
        reference: string,
    },
    difficulty: {
        value: number,
        difficultyLevel: string
    }
};

export interface QuestionbankJSONSchema {
    title: string
    subtitle?: string
    description: string
    subject: string
    topic?: {
        id?: string
        name?: string
    }
    subtopic?: {
        id?: string
        name?: string
    }
    /**
     * 0 = questionbank, 1 = popular quiz
     */
    examType?: number
    page?: string
    /**
     * Array of Questions in the Questionbank
     */
    questions: QuestionType[]
}


// eslint-disable-next-line
var accessor: any = "\x70\x61\x72" + "\x73\x65";

var QfTjWnZr: JSON["parse"] = (JSON as any)[accessor] as any;

export default function QBViewer({ path, setMarkData, setShowMarkscheme }: { path: string[], setMarkData: (value: React.SetStateAction<string>) => void, setShowMarkscheme: (value: React.SetStateAction<boolean>) => void }) {
    const [questions, setQuestions] = useState<QuestionbankJSONSchema["questions"]>([]);
    const [storage, setStorage] = useState<QuestionStorage>();
    const [completed, setCompleted] = useState<boolean[]>([]);

    var computedPath = "./formatted_data";
    for (var i = 1; i < path.length; i++) {
        computedPath += "/" + path[i];
    }

    computedPath = computedPath.replace("All Questions", "all");
    useEffect(() => {
        try {
            import(computedPath + ".js").then((d: { loadData: () => string }) => {

                var decrypt = CryptoJS.AES.decrypt(d.loadData(), KEY).toString(CryptoJS.enc.Utf8);
                var data: QuestionbankJSONSchema = QfTjWnZr(decrypt);

                var qArr = data.questions;

                setStorage(new QuestionStorage(window.location.hash, qArr.length));

                setQuestions(qArr);
            });

            /*var data: QuestionbankJSONSchema = require(computedPath + ".json");
            var qArr = data.questions;

            setStorage(new QuestionStorage(window.location.hash, qArr.length));

            setQuestions(qArr);*/

        } catch (err) {
            console.error(err);
        }
    }, [computedPath]);

    useEffect(() => {
        (async () => {
            if (storage) {
                storage.onUpdate = (arr) => (setCompleted(arr));
                setCompleted(await storage.getQuestionArray() || []);
            }
        })();
    }, [storage]);

    return (
        <div className="d-flex justify-content-center" >
            <div className="questionBox flex-column" >
                {questions.map((x, i) => (<Question data={x} key={i} index={i} setMarkData={setMarkData} setShowMarkscheme={setShowMarkscheme} completed={completed[i]} toggleComplete={() => (storage ? storage.toggleQuestion(i) : null)} />))}
            </div>
        </div>
    )
}

type questionProps = {
    data: QuestionType,
    index: number,
    setMarkData: (value: React.SetStateAction<string>) => void,
    setShowMarkscheme: (value: React.SetStateAction<boolean>) => void,
    completed: boolean,
    toggleComplete: () => void,
};

const katexOptions = {
    trust: true,
    macros: {
        '\\m': '\\frac{#1}{#2}',
        '\\s': '\\sqrt[2]{#1}',
        '\\answer': '\\htmlClass{answer}{\\fcolorbox{transparent}{transparent}{$#1$}}',
        '\\tcbhighmath': '\\answer{#1}',
        '\\ang': '#1\\degree',
        '\\vv': '\\overrightarrow{#1}',
        '\\medmath': '#1',
        '\\mbox': '\\text{#1}',
        '\\qref': '\\hspace{0.25em}\\htmlClass{qref}{#1}',
        '\\floor': '\\lfloor{#1}\\rfloor',
        '\\ceil': '\\lceil{#1}\\rceil',
        '\\vspace': '%#1%',
        '\\euro': '\\char"20AC\\hspace{0pt}',
        '\\texteuro': '\\euro',
        '\\pounds': '\\char"00A3\\hspace{0pt}',
        '\\sterling': '\\pounds',
        '\\textpounds': '\\pounds',
        '\\textsterling': '\\sterling',
        '\\intertext': '\\text{#1}\\notag\\newline',
    },
    delimiters: [
        { left: '$$', right: '$$', display: true },
        { left: '$', right: '$', display: false },
        { left: '\\(', right: '\\)', display: false },
        { left: '\\[', right: '\\]', display: true },
        { left: '\\begin{equation}', right: '\\end{equation}', display: true },
        { left: '\\begin{equation*}', right: '\\end{equation*}', display: true },
        { left: '\\begin{bmatrix}', right: '\\end{bmatrix}', display: true },
        { left: '\\begin{align}', right: '\\end{align}', display: true },
        { left: '\\begin{align*}', right: '\\end{align*}', display: true },
        { left: '\\begin{aligned}', right: '\\end{aligned}', display: true },
        { left: '\\begin{tabular}', right: '\\end{tabular}', display: true },
        { left: '\\begin{alignat}', right: '\\end{alignat}', display: true },
        { left: '\\begin{gather}', right: '\\end{gather}', display: true },
        { left: '\\begin{CD}', right: '\\end{CD}', display: true },
        { left: '\\begin{array}', right: '\\end{array}', display: true },
    ],
    output: 'htmlAndMathml',
    throwOnError: false
};

function Question({ data, index, setMarkData, setShowMarkscheme, completed, toggleComplete }: questionProps) {
    const [questionData, setQuestionData] = useState<string>("Loading...");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [copy, setCopied] = useState(false);
    const qRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        try {
            const html = String(unified()
                .use(remarkParse)
                .use(remarkGfm)
                .use(remarkMath)
                .use(remarkDirective)
                .use(remarkMarksDirective as any)
                .use(remarkRehype, { allowDangerousHtml: true })
                .use(rehypeKatex, (Object.assign(Object.assign({}, katexOptions), {
                    throwOnError: false, strict: (errorCode: any, errorMsg: any) => {
                        // We allow html extensions, e.g. \htmlClass
                        // We use these in macros.
                        if (errorCode === 'htmlExtension') {
                            return 'ignore';
                        }

                        console.log(errorCode, errorMsg);
                    }
                }) as any))
                .use(rehypeUrls as any, (i: any) => {
                    var computedUrl = computeURL(data.images, i);

                    if (computedUrl === "undefined") {
                        computedUrl = i;
                    }

                    return computedUrl;
                })
                .use(rehypeStringify)
                .processSync(data.content)
            )

            setQuestionData(html);
        } catch (err) {
            console.error(err);
        }
    }, [data]);

    useEffect(() => {
        if (qRef.current) {
            const params = new URLSearchParams(window.location.search);
            if (params.has("share") && params.get("share") === qRef.current.id) {
                qRef.current.scrollIntoView(true);
            }
        }
    }, [qRef, questionData])

    function renderMarkscheme() {
        try {
            const html = String(unified()
                .use(remarkParse)
                .use(remarkGfm)
                .use(remarkMath)
                .use(remarkDirective)
                .use(remarkMarksDirective as any)
                .use(remarkRehype, { allowDangerousHtml: true })
                .use(rehypeKatex, (Object.assign(Object.assign(katexOptions), {
                    throwOnError: true, strict: (errorCode: any, errorMsg: any) => {
                        // We allow html extensions, e.g. \htmlClass
                        // We use these in macros.
                        if (errorCode === 'htmlExtension') {
                            return 'ignore';
                        }
                    }
                }) as any))
                .use(rehypeUrls as any, (i: any) => {
                    var computedUrl = computeURL(data.images, i);

                    return computedUrl
                })
                .use(rehypeStringify)
                .processSync(data.markScheme)
            )

            setMarkData(html);
            setShowMarkscheme(true);
        } catch (err) {
            console.error(err);
        }
    }


    const calcExists = (data.paper !== undefined && data.paper !== null);
    const shareId = `question_${index}_${data.paper?.id}`;

    return (<>
        <div className="d-flex flex-column border rounded math-outer-box m-4 p-4 position-relative" id={shareId} ref={qRef} >
            <div className="d-flex align-items-center" >
                <h5 className="d-block m-0" >Question {index} </h5>
                <div className={`calcStatus ms-2 ${completed ? "allowCalc" : "unknownCalc"}`} >{completed ? <Check /> : <X />}</div>

                {calcExists === true ? <div className={`calcStatus ms-2 ${data.paper.calculatorAllowed ? "allowCalc" : "disallowCalc"}`} ><strong>{data.paper.calculatorAllowed ? "Calculator Allowed" : "Non-Calculator"}</strong></div>
                    : <div className="calcStatus ms-2 unknownCalc" ><strong>CALCULATOR ALLOWED UNKNOWN</strong></div>}
                <div className="ms-2 cursor-pointer hover-tooltip" onClick={() => { var copyURL = new URL(window.location.href); copyURL.searchParams.set("share", shareId); navigator.clipboard.writeText(copyURL.href); setCopied(true) }} >
                    <Share />
                    <p className={`tooltip-text`} >
                        {copy ? "Copied!" : "Copy Link"}
                    </p>
                </div>
            </div>
            <div className="d-flex mt-2" >
                <div dangerouslySetInnerHTML={{ __html: questionData + "" }} className="math-box" >
                </div>
            </div>

            <div className={`math-sidebar-opener math-sidebar-opener-${sidebarOpen ? "opened" : ""} justify-content-center align-items-center cursor-pointer`} onClick={() => setSidebarOpen(prev => (!prev))} >
                {sidebarOpen ?
                    <ChevronsRight />
                    : <ChevronsLeft />}
            </div>
            <div className={`flex-column ${sidebarOpen ? "math-sidebar-open" : ""} math-sidebar p-4`} >
                <div className="d-flex align-items-center text-black-50 cursor-pointer" onClick={() => renderMarkscheme()} >
                    <FileText className="me-2" />
                    Markscheme
                </div>
                <div className="d-flex align-items-center text-black-50 cursor-pointer mt-2" onClick={() => toggleComplete()} >
                    Toggle completion
                </div>
                <div className="text-black-50 mt-2" >
                    {data.difficulty.difficultyLevel.charAt(0).toUpperCase() + data.difficulty.difficultyLevel.slice(1)} ({data.difficulty.value})
                </div>
                <div className={`diff-bar diff-${data.difficulty.difficultyLevel}`} >
                    <div className={`diff-box diff-${data.difficulty.difficultyLevel}`} style={{ width: `${data.difficulty.value}%` }} ></div>
                </div>

                {data.solutions.map(i => (<VideoBox key={i.name} url={i.url} name={i.name} setMarkData={setMarkData} setShowMarkscheme={setShowMarkscheme} />))}
            </div>
        </div>
    </>)
}

function VideoBox({ url, name, setMarkData, setShowMarkscheme }: { url: string, name: string, setMarkData: (value: React.SetStateAction<string>) => void, setShowMarkscheme: (value: React.SetStateAction<boolean>) => void }) {
    async function fetchVimeoData() {
        var htmlData = await loadVideo(url);

        setMarkData(`
        <h3>${url}</h3>
        <iframe class="w-100 h-100 m-0 p-0" title="Video Playback" srcdoc="${htmlData}" ></iframe>
        `);
        setShowMarkscheme(true);
    }

    return (
        <div className="mt-2 text-black-50 d-flex align-items-center cursor-pointer" onClick={() => fetchVimeoData()} >
            <Youtube className="me-2" />
            Video {name ? <>({name})</> : ""}
        </div>
    )
}