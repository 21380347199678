import { hasProperty } from 'hast-util-has-property';
import { visit } from 'unist-util-visit';
export const rehypeUrls = (fn) => {
    const modify = (node, prop) => {
        if (hasProperty(node, prop)) {
            if (!node.properties) {
                node.properties = {};
            }
            const url = node.properties[prop];
            if (url) {
                node.properties[prop] = fn(url, node) || url;
            }
        }
    };
    return (tree) => visit(tree, 'element', (node) => {
        modify(node, 'href');
        modify(node, 'src');
    });
};