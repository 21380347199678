import localforage from "localforage";

export default class QuestionStorage {
    path: string;
    len: number;

    onUpdate: (newVal: boolean[]) => void = () => {};

    constructor(path: string, len: number) {
        this.path = path;
        this.len = len;
        localforage.getItem(window.btoa(path)).then((resolve) => {
            var data = resolve;
            if (data === null) {
                data = Array(len).fill(false);
                localforage.setItem(path, data).then();
            }
        })
    }

    async getQuestionArray(): Promise<boolean[] | null> {
        return localforage.getItem(this.path);
    }

    async setQuestionArray(questionArray: boolean[]) {
        await localforage.setItem(this.path, questionArray);
        this.onUpdate(questionArray);
    }

    async toggleQuestion(index: number) {
        var arr: boolean[] | null = await localforage.getItem(this.path);

        if (arr) {
            arr[index] = !arr[index];
            await localforage
        } else {
            arr = Array(this.len).fill(false);
            arr[index] = true;
        }

        await localforage.setItem(this.path, arr);
        this.onUpdate(arr);
    }
}