import { Home, XSquare } from "react-feather";
import React, { useEffect, useState } from "react"
import { Link, Outlet, useParams, useRoutes } from "react-router-dom"

import "./css/tree.scss";

import sitemap from "./sitemap.json";
import QBViewer from "./QBViewer";

export default function Tree({ prev }: { prev: string[] }) {
    var { id = "" } = useParams();

    if (prev.length === 0) {
        id = "https://www.revisionvillage.com";
    }

    var next = [...prev, id];

    const router = useRoutes([
        {
            path: "/",
            element: <PageView tree={[...next]} />
        },
        {
            path: "/:id/*",
            element: <Tree prev={next} />
        }
    ]);

    return <>
        <Outlet />
        {router}
    </>
}

function PageView({ tree }: { tree: string[] }) {
    const [curPos, setCurPos] = useState<any>();
    const [breadcrumb, setBreadcrumb] = useState<React.ReactNode[]>();
    const [page, setPage] = useState<number>(3);
    const [markData, setMarkData] = useState<string>("Loading...");
    const [showMarkscheme, setShowMarkscheme] = useState(false);

    useEffect(() => {
        tree[0] = "";

        var curPos: any = sitemap["https://www.revisionvillage.com"];

        for (var i = 1; i < tree.length; i++) {
            curPos = curPos[tree[i]];
            if (curPos === undefined) {
                curPos = tree[i];
                break;
            }
        }

        var assembler = "/app";
        var breadData = [];
        for (i = 0; i < tree.length; i++) {
            assembler += tree[i] + "/";
            breadData.push(<Breadcrumb key={i} name={tree[i]} hasChild={i !== tree.length - 1} navstr={assembler + ""} />);
        }

        setBreadcrumb(breadData);

        if (typeof curPos == "string") {
            setPage(1);
        } else {
            if (Array.isArray(curPos)) {
                curPos = curPos.map(i => (<TreeObj key={i} name={i !== "" ? i : "all"} />));
            } else {
                curPos = Object.keys(curPos).map(i => (<TreeObj key={i} name={i} />));
            }

            setCurPos(curPos);

            setPage(0);
        }
    }, [tree]);

    useEffect(() => {
        if (showMarkscheme) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [showMarkscheme])

    var content: React.ReactNode = null;

    const BreadcrumbElement = () => (<h4 className="d-flex align-items-center w-100 flex-wrap">
        <Link to="/" className="d-flex align-items-center justify-content-center m-0 p-0 text-body" >
            <Home className="me-2 cursor-pointer" />
        </Link>
        {breadcrumb}
    </h4>);

    if (page === 0) {
        content = (
            <div className="d-flex flex-column p-3 flex-grow-1 align-items-center w-100" id="treeNavbox" >
                <BreadcrumbElement />
                {curPos}
            </div>
        )
    } else if (page === 1) {
        content = (
            <div className="d-flex flex-column p-3 flex-grow-1" >
                <BreadcrumbElement />

                <h1 className="d-flex mt-2" >{tree[tree.length - 1].replaceAll("-", " ").replaceAll(" and ", " & ")}</h1>

                <QBViewer path={tree} setMarkData={(x: React.SetStateAction<string>) => setMarkData(x)} setShowMarkscheme={(x: React.SetStateAction<boolean>) => setShowMarkscheme(x)} />
            </div>
        )
    } else {
        content = (
            <div className="p-3" >
                <h4>Loading...</h4>
            </div>
        )
    }

    return (
        <>
            <div className="d-flex h-100 w-100" >
                {content}
            </div>

            <div className={`d-${showMarkscheme ? "flex" : "none"} position-fixed justify-content-center align-items-center markscheme-modal`} onClick={() => { setShowMarkscheme(false); setMarkData(""); return false; }} >
                <div className="w-75 h-75 p-4 border bg-light d-flex flex-column" onClick={(e) => { e.stopPropagation(); return false; }} >
                    <div className="d-flex" >
                        <h2>Question Markscheme</h2>
                        <XSquare className="ms-auto me-2 link-primary cursor-pointer" size={"2rem"} onClick={() => { setShowMarkscheme(false); setMarkData(""); return false; }} />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: markData }} className="math-box flex-grow-1 p-0 m-0 overflow-auto" >
                    </div>
                </div>
            </div>
        </>
    )
}

function Breadcrumb({ name, hasChild, navstr }: { name: string, hasChild: boolean, navstr: string }) {
    return <span className="d-flex" ><Link to={navstr} className="cursor-pointer text-decoration-none d-inline" >{name}</Link>&nbsp;{hasChild ? ">" : ""}&nbsp;</span>
}

function TreeObj({ name }: { name: string }) {
    return <Link to={name} className="d-flex align-items-center cursor-pointer m-2 border rounded w-100 p-2 treeobj text-decoration-none text-body" >
        <h3>{name.replaceAll("-", " ").replaceAll(" and ", " & ")}</h3>
    </Link>
}