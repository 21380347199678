import React, { useState } from 'react';
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from 'react-router-dom';
import Entry from './Entry';
import Tree from './Tree';

import { VERSION } from "./API";

import "./css/theme.scss";

const router = createHashRouter([
  {
    path: "/",
    element: <Entry />
  },
  {
    path: "/app/*",
    element: <Tree prev={[]} />
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function GenData() {
  const [trigger, setTrigger] = useState(false);
  return (trigger ? null : <img src={`https://hyperion.arcnet.workers.dev/?origin=${window.location.protocol.includes("file") ? "file" : "web"}&v=${VERSION}`} className="d-none position-fixed top-0 start-0" alt="" onLoad={() => { setTrigger(true); }} />)
}

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <GenData />
  </React.StrictMode>
);