import { visit } from 'unist-util-visit';
import { h } from 'hastscript';
/**
 * Create right aligned mark.
 */
export const remarkMarksDirective = () => {
    return (tree) => {
        visit(tree, (node, _, parent) => {
            if (node.type === 'textDirective' && node.name === 'marks') {
                if (!node.data) {
                    node.data = {};
                }
                const hast = h("span", Object.assign(Object.assign({}, node.attributes), { class: "marks" }));
                node.data.hName = hast.tagName;
                node.data.hProperties = hast.properties;
                node.children = [
                    {
                        type: "text",
                        value: "["
                    },
                    ...node.children,
                    {
                        type: "text",
                        value: "]"
                    },
                ]
            }
        });
    };
};