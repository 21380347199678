import { QuestionType } from "./QBViewer";

export const VERSION = "0.5.2";

export const MAIN_URL = "https://sequoia-super-bacon.glitch.me";

export const SUBDOMAIN = "sequoia-super-bacon";

export const DOWNLOAD_URL = "https://glitch.com/edit/#!/sequoia-super-bacon";

export async function loadVideo(url: string) {
    var data = (await (await fetch(`https://intelarc.arcnet.workers.dev/?url=${url}`)).text()).replaceAll("\"", "&quot;");

    return data;
}

export function computeURL(images: QuestionType["images"], i: any) {
    return images.find(x => (x.file_id === i.replace("question:", "")))?.url + "";
}