import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Changelog from "./tsx/Changelog";
import { DOWNLOAD_URL, MAIN_URL, SUBDOMAIN, VERSION } from "./API";

export default function Entry() {
    return <div className="d-flex flex-column align-items-center pt-3 text-center" >
        <div>
            <h1>Mortar</h1>
        </div>

        <div className="mt-3" >
            <Link to={"/app"}><Button variant="primary" >Enter</Button></Link>
        </div>
        <div className="mt-2" >
            {
                window.location.protocol.includes("file") ? <>DOWNLOADED VERSION ({VERSION}): Select functionality may not work on this version, especially offline.</> : <Link to={DOWNLOAD_URL} ><Button variant="primary" >Download (Click tools -&gt; export -&gt; download)</Button></Link>
            }
        </div>

        <div className="mt-2" >
            <Link to="https://discord.gg/4bTbQPkDR9"><Button variant="discord"  >Join Discord</Button></Link>
        </div>

        <div className="mt-2" >
            {
                !window.location.href.includes(SUBDOMAIN) && !window.location.protocol.includes("file") ? <>NOTE: You may be accessing a mirror. The main source for updates is at <Link to={MAIN_URL}>{MAIN_URL}</Link> </> : null
            }
        </div>

        {window.location.protocol.includes("file") || !window.location.href.includes(SUBDOMAIN) ?
            <iframe className="mt-2 overflow-hidden flex-shrink-1" style={{ height: "2rem" }} src={`${MAIN_URL}/version.html?version=${VERSION}`} title="Update Status" >
            </iframe>
            : null}

        <h1 className="mt-4" >
            <em>grind</em> practice questions
        </h1>

        <img src={process.env.PUBLIC_URL + 'pestle.png'} draggable="false" style={{ maxWidth: "800px" }} alt="" />

        <h2 className="mt-2" >
            Changelog
        </h2>

        <Changelog />
    </div>
}